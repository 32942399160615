.Home {
    /* I noticed the slide animation was causing the overflow to periodical trigger in mobile view, so I set this to hidden. */
    overflow: hidden;
}

.logo-container{
    position: relative;
    opacity: .7;
}

.spinning-border {
    border: 3px inset var(--secondary-color);
    border-radius: 50%;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation-name: spin;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    opacity: .75;
}

.fadeInFadeOut {
    white-space: nowrap;
    overflow: hidden;
    animation: fadeInFadeOut 5s linear;
    animation-iteration-count: infinite;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

@keyframes fadeInFadeOut {
    0% {
        opacity: 0;
        transform: translate(-200px, 0);
    }
    10% {
        opacity: 1;
        transform: translate(0, 0);
    }
    90% {
        opacity: 1;
        transform: translate(0, 0);
    }
    100% {
        opacity: 0;
        transform: translate(200px, 0);
    }
}