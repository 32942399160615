/* Global Variables */
:root {
  /* Colors */
  --primary-color: #0d6efd;
  --secondary-color: #6c757d;
  --danger-color: #dc3545;
  --hover-color: #cdd7e6;
  --dark-color: #5e5e5e;
  --popup-background-color: rgba(0, 0, 0, 0.4);

  /* Sizes */
  --nav-height: 56px;
  --narrow-content-limit: 1000px;
  --spacer: 1rem;
}

/* Global Modifications */
h1 {
  text-align: center;
}

h5, h6 {
  color: var(--secondary-color);
  font-weight: bold;
}

/* Custom Classes */
.cb-narrow-content {
  margin-right: auto;
  margin-left: auto;
  max-width: var(--narrow-content-limit);
}

.cb-icon-button {
  border-radius: .25rem;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  padding-left: .25rem;
  padding-right: .25rem;
}

.cb-icon-button:hover {
  background-color: var(--secondary-color);
  color: white;
}

.cb-hidden {
  display: none;
}

@media only screen and (max-width: 750px) {
  .cb-hidden-mobile {
    display: none;
  }
}

/* Test Classes */
.pink-border {
  border: 3px pink solid;
}

/* React Defaults */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
