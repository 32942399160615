.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--popup-background-color);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.popup-inner {
    position: relative;
    width: 100%;
    max-width: 640px;
    min-height: 250px;
    max-height: 100%;
    margin: 10px;
}

.popup-inner.close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
}