.flexbox-container {
    margin-right: auto;
    margin-left: auto;
    max-width: var(--narrow-content-limit);
    display: flexbox;
}

.flexbox-item {
    width: 100%;
}

.accordion-title {
    padding: 0;
}