.App {
  min-height: 100vh;
}

.BodyWrapper {
  border-top: 3px var(--secondary-color) solid;
  height: calc(100vh - var(--nav-height));
  position: fixed;
  overflow: auto;
  width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
